$parent: .header;
$component: $(parent)-drawer;
$component  {
  z-index: $zIndexGlobalHeaderDrawer;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: $colorWhite;
  display: none;

  $(parent)--is-drawer-open & {
    display: block;
  }

  &__bar {
    background-color: $colorWhite;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background-color: $colorWhite;
    z-index: $zIndexLocalContext;

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__bar__container {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    display: flex;
    align-items: center;
    height: spacing(6);
    @mixin breakpoint $breakpointTablet {
      padding-left: spacing(4);
      padding-right: spacing(4);
      height: spacing(8);
    }
    @mixin breakpoint $breakpointTabletLandscape {
      height: spacing(10);
    }
    @mixin breakpoint $breakpointScrollHeader {
      padding-top: spacing(2);
      padding-bottom: spacing(2);
      align-items: flex-end;
      height: auto;
    }
  }

  &__bar__left,
  &__bar__right {
    width: 50%;
    @mixin breakpoint $breakpointScrollHeader {
      width: spacing(20);
      padding-bottom: spacing(3);
    }
  }

  &__bar__left,
  &__bar__center,
  &__bar__right {
    display: flex;
  }

  &__bar__left {
    text-align: left;
    justify-content: flex-start;
  }

  &__bar__center {
    flex-grow: 1;
    display: block;
  }

  &__bar__right {
    justify-content: flex-end;
  }

  &__bar__burger {
    width: spacing(4);
    height: spacing(4);
  }

  &__bar__language-switcher {
    width: spacing(4);
    height: spacing(4);
  }

  &__bar__phone-number {
    width: spacing(4);
    height: spacing(4);

    &__link {
      display: flex;
      line-height: 0;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__icon {
      display: block;
      width: spacing(2.5);
    }
  }

  &__bar__primary-cta,
  &__bar__secondary-cta {
    width: spacing(15);
    @mixin breakpoint $breakpointScrollHeader {
      width: spacing(20);
    }
  }

  &__bar__brand {
    display: block;
    text-align: center;
    @mixin breakpoint $breakpointScrollHeader {
      padding-bottom: spacing(1);
    }

    [data-theme="waldhaus"] & {
      color: $waldhausBarley;
    }
  }

  &__bar__logo {
    line-height: 1;
    display: block;
    width: spacing(16);
    color: currentColor;
    margin: 0 auto;

    @mixin breakpoint $breakpointTabletLandscape {
      width: spacing(20);
      opacity: 1;
    }

    @mixin breakpoint $breakpointScrollHeader {
      width: spacing(26);
    }

    &--waldhaus,
    &--chalet-belmont {
      width: spacing(10);

      @mixin breakpoint $breakpointTablet {
        width: spacing(12);
        opacity: 1;
      }

      @mixin breakpoint $breakpointTabletLandscape {
        width: spacing(14);
        opacity: 1;
      }
      @mixin breakpoint $breakpointScrollHeader {
        width: spacing(16);
      }
    }


  }

  &__bar__left {
    text-align: left;
  }

  &__bar__left__items {
    display: inline-flex;
    margin-left: spacing(-2);
    margin-right: spacing(-2);

    @mixin breakpoint $breakpointScrollHeader {
      margin-left: spacing(-1.25);
      margin-right: spacing(-1.25);
    }
  }

  &__bar__left__item {
    display: inline-block;
    vertical-align: middle;
    padding-left: spacing(1.25);
    padding-right: spacing(1.25);

    &--burger {
      display: inline-block;
      @mixin breakpoint $breakpointScrollHeader {
        display: none;
      }
    }

    &--primary-cta {
      display: none;
      @mixin breakpoint $breakpointTablet {
        display: inline-block;
      }
    }
  }

  &__bar__center {
    text-align: center;
  }

  &__bar__center__items {
    display: block;
    margin: 0 auto;
  }

  &__bar__center__item {
    display: block;
  }

  &__bar__right {
    text-align: right;
  }

  &__bar__right__items {
    display: inline-flex;
    margin-left: spacing(-2);
    margin-right: spacing(-2);

    @mixin breakpoint $breakpointScrollHeader {
      margin-left: spacing(-1.25);
      margin-right: spacing(-1.25);
    }
  }

  &__bar__right__item {
    display: inline-block;
    vertical-align: middle;
    padding-left: spacing(1.25);
    padding-right: spacing(1.25);

    &--secondary-cta {
      display: none;
      @mixin breakpoint $breakpointTablet {
        display: inline-block;
      }
    }

    &--language-switcher {
      display: none;
      @mixin breakpoint $breakpointTablet {
        display: inline-block;
      }
      @mixin breakpoint $breakpointScrollHeader {
        display: none;
      }
    }

    &--phone-number {
      display: inline-block;
      @mixin breakpoint $breakpointTablet {
        display: none;
      }
    }
  }

  &__container {
    padding: spacing(10) spacing(4);
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: zIndexLocalForeground;
    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(14) spacing(4);
    }
  }

  &__section {
    text-align: center;
    max-width: $containerXsmall;
    margin: 0 auto;
    &:not(:last-child) {
      padding-bottom: spacing(4);
    }
  }

  &__main-navigation {
    display: inline-block;
  }

  &__main-navigation__marriott {
    text-align: center;
    padding-bottom: spacing(3);
  }

  &__main-navigation__marriott-logo {
    color: $carillonDoveGrey;
    display: inline-block;
    width: 200px;
  }

  &__main-navigation__list {
    list-style-type: none;
  }

  &__main-navigation__item {
    padding-bottom: spacing(1);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $carillonDoveGrey;
    }
  }

  &__main-navigation__action {
    display: inline-block;

    &.is-active {
      opacity: 1;

      [data-theme="carillon"] & {
        color: $carillonRiverBed;
      }

      [data-theme="waldhaus"] & {
        color: $waldhausYellowMetal;
      }
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__secondary-navigation {
    display: inline-block;
  }

  &__language-switcher {
    text-align: center;
    display: inline-block;

    @mixin breakpoint $breakpointTablet {
      display: none;
    }
  }

  &__secondary-navigation__list {
    list-style-type: none;
  }

  &__secondary-navigation__item {

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $carillonDoveGrey;
    }
  }

  &__contact {}

  &__address,
  &__phone {
    display: block;

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__address,
  &__phone-number {
    line-height: spacing(4);
  }

  &__phone-number {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__phone-number__icon {
    width: spacing(2);
    display: inline-block;
    line-height: 0;
    vertical-align: middle;

    [data-theme="carillon"] & {
      color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      color: $colorBarleyCorn;
    }
  }

  &__newsletter {}

  &__newsletter__title {
    padding-bottom: spacing(1);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $carillonDoveGrey;
    }
  }

  &__social {
    display: inline-block;

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $carillonDoveGrey;
    }
  }
}
