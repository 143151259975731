$component: .booking-calendar;
$component  {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: $zIndexGlobalPopup;
  display: none;

  ::selection {
    background-color: transparent;
  }

  &.is-active {
    display: block;
  }

  [data-theme="carillon"] & {
    color: $carillonDoveGrey;
  }

  [data-theme="waldhaus"] & {
    color: $waldhausDoveGrey;
  }

  &__frame {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    width: 100%;
    background-color: $colorWhite;
    height: auto;

    @mixin breakpoint $breakpointTablet {
      background-color: transparent;
      align-items: center;
      padding: spacing(4);
    }
  }

  &__container {
    background-color: $colorWhite;
    width: 100%;
    border-radius: spacing(1);
    pointer-events: all;

    @mixin breakpoint $breakpointTabletLandscape {
      box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);
      max-width: $containerBookingCalendarMulti;
    }
  }

  &__dates {
    background-color: $colorAlabaster;
    justify-content: center;
    align-items: center;
    display: flex;

    @mixin breakpoint $breakpointTablet {
      display: none;
    }
  }

  &__date {
    padding: spacing(2);
    text-align: center;
    width: 50%;
    &:not(:last-child) {
      border-right: 1px solid rgba($colorBlack, 0.1);
    }

    &:first-child {
      text-align: right;
    }

    &:last-child {
      text-align: left;
    }
  }

  &__container {
    display: inline-block;
    text-align: center;
  }

  &__date-label {
    line-height: 1;

    [data-theme="carillon"] & {
      color: $carillonHippieBlue;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__date-values {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__body {
    position: relative;
    overflow: hidden;
  }

  &__date-picker-aspect {
    padding-bottom: 400px;
    height: 0;
  }

  &__date-picker-container {
    @mixin fill-parent;
    text-align: center;
  }

  &__footer {
    bottom: 0;
    left: 0;
    border-top: 1px solid rgba($colorBlack, 0.1);
    width: 100%;
    padding: spacing(2.5);

    @mixin breakpoint $breakpointTablet {
      padding: 0;
      position: relative;
    }

  }

  &__form {
    max-width: $containerXxsmall;
    margin: 0 auto;
    @mixin breakpoint $breakpointTablet {
      max-width: none;
    }
  }

  &__form-sections {
    @mixin breakpoint $breakpointTablet {
      display: flex;
    }
  }

  &__form-section {
    @mixin breakpoint $breakpointTablet {
      padding: spacing(2);
      width: 50%;

      &:not(:last-child) {
        border-right: 1px solid rgba($colorBlack, 0.1);
      }
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(3);
    }
  }

  &__form-section-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @mixin breakpoint $breakpointTablet {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
    }
  }

  &__form-fields {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__instructions-field {
    display: none;
    @mixin breakpoint $breakpointTablet {
      display: block;
      width: auto;
    }
  }

  &__adult-field {
    width: 100%;
    width: calc(50% - spacing(1));
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
    @mixin breakpoint $breakpointTablet {
      width: spacing(10);
    }
  }

  &__child-field {
    width: 100%;
    width: calc(50% - spacing(1));
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
    @mixin breakpoint $breakpointTablet {
      width: spacing(10);
    }
  }

  &__promo-field {
    width: 100%;
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
    @mixin breakpoint $breakpointTablet {
      width: calc(50% - spacing(1));
      width: spacing(10);
    }
  }

  &__field-label {
    text-align: center;
    padding-bottom: spacing(1);
    @mixin breakpoint $breakpointTablet {
      text-align: left;
    }
  }

  &__field-input {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__action {
    @mixin uptoBreakpoint $breakpointTablet {
      padding-top: spacing(1);
      width: 100%;
    }
  }
}
