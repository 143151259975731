$component: .callout-card;
$component  {

  &__frame {
  }

  &__container {
    @mixin breakpoint $breakpointTabletLandscape {
      display: flex;
    }
  }

  &__media,
  &__content {
    width: 100%;
    @mixin breakpoint $breakpointTabletLandscape {
       width: 50%;
    }
  }

  &__media {
    position: relative;
  }

  &__media-aspect {
    padding-bottom: aspect-ratio(2,3);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: aspect-ratio(1,1);
    }
  }

  &__media-link {
    display: block;
  }

  &__content {
    background-color: $colorWhite;
    text-align: left;
    position: relative;

    @mixin breakpoint $breakpointTabletLandscape {
      display: flex;
      align-items: center;
    }
  }

  &__content-frame {
    padding: spacing(2.4);
    width: 100%;
    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }
    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(6);
    }
  }

  &__title {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__subtitle {
    padding-bottom: spacing(2);
    max-width: $containerXsmall;

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__body {
    padding-bottom: spacing(3);
    max-height: 5.75em;
    overflow: hidden;
    @mixin breakpoint $breakpointTablet {
      max-height: none;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      padding-right: 0;
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__ctas {
    margin-left: spacing(-0.5);
    margin-right: spacing(-0.5);
    padding-top: spacing(2);
  }

  &__cta {
    display: inline-block;
    padding: 0 spacing(0.5) spacing(1) spacing(0.5);
    @mixin uptoBreakpoint $breakpointMobile {
      width: 100%;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    padding: spacing(1.25);
    line-height: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    border: none;

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__close-icon {
    display: inline-block;
    width: spacing(2.5);
  }
}
