$component: .background-pattern;
$component {
  @mixin fill-parent;
  pointer-events: none;
  z-index: -1;
  opacity: 0.5;

  &--header-drawer {
    margin-top: spacing(6);
  }

  &__background {
    @mixin fill-parent;
    background-color: $colorWhite;
    background-size: 100% auto;
    background-repeat: repeat-y;

    opacity: 0;
    will-change: opacity;
    transition-property: opacity;
    transition-duration: resolve($transitionDuration * 4)ms;
    transition-timing-function: $transitionEaseOutExpo;

    .is-loaded & {
      opacity: 1;
    }
  }

  &__gradient {
    position: absolute;
    height: spacing(5);
    left: 0;
    top: 0;
  }

}

#main-content {
  position: relative;
}
