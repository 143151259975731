$component: .booking-widget;
$component  {

  &__frame {
    width: spacing(20);
  }

  &__container {
    position: relative;
    overflow: hidden;
    border-radius: spacing(1);

    [data-theme="carillon"] & {
      background-color: $carillonRiverBed;
      color: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      background-color: $waldhausYellowMetal;
      color: $colorWhite;
    }
  }

  &__header {}

  &__footer {}

  &__dates {
    padding: spacing(1) 0;
    display: flex;
    cursor: pointer;
  }

  &__date {
    width: 50%;
    position: relative;
    padding: 0 spacing(1);

    &:not(:last-child) {
      border-right: 1px solid rgba($colorWhite, 0.25);
    }
  }

  &__date-label {
    text-align: left;
  }

  &__date-values {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: spacing(1);
  }

  &__date-day {
    padding-right: spacing(2);
  }

  &__date-caret {
    position: absolute;
    top: 50%;
    right: spacing(1);
    height: spacing(1);
    width: spacing(1);
    color: currentColor;
    margin-top: spacing(0.5);
    pointer-events: none;
    opacity: 0.5;
    line-height: 0;
    opacity: 0.5;
  }
}
