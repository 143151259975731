/**

  required global type modifier styles:
  ---------------------

  billboard
  h1
  h2
  h3
  h4
  h5
  h6
  section-title
  feature-title
  body
  body-alt
  body-title
  body-small
  body-small-no-letter-spacing
  body-small-bold
  quote
  caption
  caption-small
  video-caption
  button

**/

$typeHeadline: "FreightDisp Pro Book Italic-Black", serif;
$typeBody: "Mark Pro", sans-serif;
$typeBodyBold: "Mark Pro Bold", sans-serif;

/*
Global style(s) for wysiwyg editor
*/
body {
  font-family: $typeBody;
}

strong {
  font-family: $typeBodyBold;
}

.type {

  &--billboard {
    font-family: $typeHeadline;
    font-size: px-to-rem(50);
    line-height: 60px;
    @mixin breakpoint $breakpointTablet {
      font-size: px-to-rem(60);
      line-height: 70px;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(80);
      line-height: 90px;
    }
  }

  &--h1 {
    font-family: $typeHeadline;
    font-size: px-to-rem(30);
    line-height: 40px;
    @mixin breakpoint $breakpointTablet {
      font-size: px-to-rem(40);
      line-height: 50px;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(50);
      line-height: 60px;
    }
  }

  &--h2 {
    font-family: $typeHeadline;
    font-size: px-to-rem(30);
    line-height: 1.5;
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(40);
      line-height: 1.5;
    }
  }

  &--h3 {
    font-family: $typeHeadline;
    font-size: px-to-rem(25);
    @mixin breakpoint $breakpointTablet {
      font-size: px-to-rem(30);
      line-height: 40px;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(40);
      line-height: 50px;
    }
  }

  &--h4 {
    font-family: $typeHeadline;
    font-size: px-to-rem(25);
    line-height: 1.5;
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(30);
    }
  }

  &--h5 {
    font-family: $typeHeadline;
    font-size: px-to-rem(25);
    line-height: 30px;
  }

  &--h6 {
    font-family: $typeBody;
    font-size: px-to-rem(16);
  }

  &--section-title,
  &--feature-title {
    font-family: $typeBodyBold;
    font-size: px-to-rem(13);
    letter-spacing: px-to-em(2.17);
    text-transform: uppercase;
    font-weight: bold;
  }

  &--body {
    font-family: $typeBody;
    font-size: px-to-rem(15);

    em {
      font-family: $typeBody;
    }
  }

  &--body-bold {
    font-family: $typeBodyBold;
    font-size: px-to-rem(15);

    em {
      font-family: $typeBody;
    }
  }

  &--body-alt {
    font-family: $typeHeadline;
    font-size: px-to-rem(16);
  }

  &--body-title {
    font-family: $typeBody;
    font-size: px-to-rem(18);
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(20);
    }
  }

  &--body-small {
    font-family: $typeBody;
    font-size: px-to-rem(13);
    letter-spacing: px-to-em(0.5);
  }

  &--body-xsmall {
    font-family: $typeBody;
    font-size: px-to-rem(10);
    letter-spacing: px-to-em(1);
  }

  &--body-xsmall-uppercase {
    font-family: $typeBody;
    font-size: px-to-rem(10);
    letter-spacing: px-to-em(1);
    text-transform: uppercase;
  }

  &--body-small-no-letter-spacing {
    font-family: $typeBody;
    font-size: px-to-rem(13);
  }

  &--body-small-bold {
    font-family: $typeBodyBold;
    font-size: px-to-rem(13);
    letter-spacing: px-to-em(0.5);
  }

  &--quote {
    font-family: $typeHeadline;
    font-size: px-to-rem(18);
    line-height: 25px;
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(25);
      line-height: 35px;
    }
  }

  &--review {
    font-family: $typeBody;
    font-size: px-to-rem(18);
    line-height: 25px;
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(20);
      line-height: 30px;
    }
  }

  &--caption {
    font-family: $typeBody;
    font-size: px-to-rem(15);
  }

  &--caption-small {
    font-family: $typeBody;
    font-size: px-to-rem(13);
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(15);
    }
  }

  &--video-caption {
    font-family: $typeHeadline;
    font-size: px-to-rem(20);
    @mixin breakpoint $breakpointTabletLandscape {
      font-size: px-to-rem(25);
    }
  }

  &--button {
    font-family: $typeBodyBold;
    font-size: px-to-rem(14);
    letter-spacing: px-to-em(0.58);
  }

  &--navigation {
    font-family: $typeBodyBold;
    font-size: px-to-rem(14);
    letter-spacing: px-to-em(0.5);
  }

  &--sub-navigation {
    font-family: $typeBody;
    font-size: px-to-rem(14);
    letter-spacing: px-to-em(0.5);
  }

  &--navigation-drawer {
    font-family: $typeBodyBold;
    font-size: px-to-em(18);
    letter-spacing: px-to-em(0.75);
    line-height: 35px;
  }

  &--navigation-drawer-secondary {
    font-family: $typeBody;
    font-size: px-to-em(14);
    line-height: 35px;
  }

  &--pricing-table-cell {
    font-family: $typeBody;
    font-size: px-to-em(20);
    letter-spacing: px-to-em(0.5);
  }

  &--booking-calendar-alt-label {
    font-family: $typeHeadline;
    font-size: px-to-em(20);
    line-height: 1;
  }

  &--booking-widget-label {
    font-family: $typeHeadline;
    font-size: px-to-em(12);
    line-height: 1;
  }

  &--booking-widget-month {
    font-family: $typeBody;
    text-transform: uppercase;
    font-size: px-to-em(11);
    line-height: 1;
  }

  &--booking-widget-day {
    font-family: $typeHeadline;
    text-transform: uppercase;
    font-size: px-to-em(30);
    line-height: 1;
  }
}
