$component: .input;
$component  {

  &--text-field {
    width: 100%;
    line-height: spacing(2);
    padding: spacing(1);
    background-color: $colorWhite;
    border-radius: spacing(1);
    border: 1px solid;
    -webkit-appearance: none;
    height: spacing(4);

    [data-theme="carillon"] & {
      border-color: $carillonCasper;
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      border-color: $waldhausYellowMetal;
      color: $waldhausDoveGrey;
    }
  }

  &--textarea {
    width: 100%;
    background-color: $colorWhite;
    border-radius: spacing(1);
    border: 1px solid;
    min-height: spacing(20);
    padding: spacing(1);
    vertical-align: bottom;
    -webkit-appearance: none;

    [data-theme="carillon"] & {
      border-color: $carillonCasper;
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      border-color: $waldhausYellowMetal;
      color: $waldhausDoveGrey;
    }
  }

  &--fake-select {
    cursor: pointer;
    color: currentColor;
    background-color: transparent;
    height: spacing(4);
    line-height: spacing(4);
    border: none;
    width: 125%;
    appearance: none;
    -webkit-appearance: none;
    padding: 0 spacing(1);
    border-radius: spacing(1);
    text-align: left;
    -webkit-appearence: none;

    span[class*="type--"] {
      line-height: spacing(4);
    }
  }

  &--select {
    cursor: pointer;
    color: currentColor;
    background-color: transparent;
    height: spacing(4);
    line-height: spacing(4);
    border: none;
    width: 140%;
    appearance: none;
    -webkit-appearance: none;
    padding: 0 spacing(1) 0 spacing(2);
    border-radius: spacing(1);

    &-wrapper {
      display: inline-block;
      position: relative;
      width: 100%;
      overflow: hidden;
      border: 1px solid;
      border-radius: spacing(1);
      vertical-align: middle;
      background-color: $colorWhite;

      [data-theme="carillon"] & {
        border-color: $carillonCasper;
        color: $carillonDoveGrey;
      }

      [data-theme="waldhaus"] & {
        border-color: $waldhausYellowMetal;
        color: $waldhausDoveGrey;
      }
    }

    &-caret {
      position: absolute;
      top: spacing(2);
      right: spacing(1);
      height: spacing(1);
      width: spacing(1);
      color: currentColor;
      margin-top: spacing(-0.5);
      pointer-events: none;
      line-height: 0;
      opacity: 0.5;
    }
  }

  &--selection {
    opacity: 0;
    position: absolute;
    left: 0;

    &:checked {

      + $(component)--selection-box {

        [data-theme="carillon"] & {
          color: $carillonCasper;
        }

        [data-theme="waldhaus"] & {
          color: $waldhausYellowMetal;
        }
      }
    }

    &-wrapper {
      display: inline-block;
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-left: spacing(3.5);
    }

    &-box {
      width: spacing(2.5);
      height: spacing(2.5);
      position: absolute;
      border-radius: 5px;
      border: 1px solid;
      left: 0;
      color: transparent;

      [data-theme="carillon"] & {
        border-color: $carillonCasper;
      }

      [data-theme="waldhaus"] & {
        border-color: $waldhausYellowMetal;
      }
    }

    &-checkmark {
      position: absolute;
      top: spacing(0.1);
      left: spacing(0.1);
      height: spacing(2.1);
      width: spacing(2.1);
      color: currentColor;
      pointer-events: none;
      line-height: 0;
    }

    &-label {
      line-height: spacing(2.5);
    }
  }

  &--checkbox-field,
  &--radio-field {
    line-height: 1;
  }

  &--inline-label {
    line-height: 1.75em;
    cursor: pointer;
  }

  &--hidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
}
