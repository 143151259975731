$component: .newsletter-signup-form;
$component  {

  &__label {
    @mixin screen-reader-only;
  }

  /*
  modifiers
  */
  &--footer {

    $(component)__wrapper {
      border-bottom: 1px solid $colorWhite;
      display: flex;
      align-items: center;

      [data-theme="carillon"] & {

        $(component)__wrapper {
          border-color: $colorWhite;
        }

        $(component)__input {
          &::placeholder {
            color: $colorWhite;
          }
        }

        $(component)__submit{
          color: $colorWhite;

          > button {
            color: $colorWhite;
          }
        }
      }

      [data-theme="waldhaus"] & {
        background-color: $waldhausYellowMetal;

        $(component)__wrapper {
          border-color: $colorWhite;
        }

        $(component)__input {
          &::placeholder {
            color: $colorWhite;
          }
        }

        $(component)__submit{
          color: $colorWhite;

          > button {
            color: $colorWhite;
          }
        }
      }
    }
    $(component)__success_message,
    $(component)__error_message {
      color: $colorWhite;
    }
  }

  &--footer,
  &--header-drawer {
    $(component)__wrapper {
      &.hide {
        display: none;
      }
    }

    $(component)__success_message,
    $(component)__error_message {
      display: none;
      color: $colorWhite;
      &.show {
        display: block;
      }
    }

    $(component)__error_message {
      padding: 17.5px 0;
    }
  }

  &--header-drawer {
    $(component)__success_message,
    $(component)__error_message {
      color: $carillonDoveGrey;
    }


    $(component)__field,
    $(component)__submit {
      width: 100%;
    }

    $(component)__input {
      text-align: center;
    }

    $(component)__submit {

      > button {
        width: 100%;
      }
    }

    $(component)__field {
      border-bottom: 2px solid;
    }

    [data-theme="carillon"] & {
      $(component)__field {
        border-color: rgba($carillonDoveGrey, 0.25);
      }

      $(component)__input {
        color: $carillonDoveGrey;
          &::placeholder {
            color: rgba($waldhausDoveGrey, 0.5);
          }
        }

      $(component)__submit {
         > button {
          color: $carillonDoveGrey;
         }
      }
    }

    [data-theme="waldhaus"] & {
      $(component)__field {
        border-color: rgba($waldhausDoveGrey, 0.25);
      }

      $(component)__input {
        color: $waldhausDoveGrey;
          &::placeholder {
            color: rgba($waldhausDoveGrey, 0.5);
          }
        }

      $(component)__submit {
        > button {
          color: $waldhausDoveGrey;
        }
      }
    }
  }

  &__label {
    @mixin screen-reader-only;
  }

  &__field {
    width: 100%;
  }

  &__submit {
    text-align: center;
  }

  & &__input {
  width: 100%;
  border: none;
  appearance: none;
  background-color: transparent;
  color: $colorWhite;
  line-height: spacing(4);

    @mixin ie11-only {
      line-height: normal;
      padding: spacing(4);
    }

    &::placeholder {
      color: $colorWhite;
    }
  }
}
