$component: .ruled-specs;
$component  {

  &__list {
    border-top: 1px solid rgba($colorBlack, 0.05);;
    list-style-type: none;
  }

  &__item {
    padding: spacing(0.75) 0;
    border-bottom: 1px solid rgba($colorBlack, 0.05);
  }

  &__container {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  &__icon,
  &__title,
  &__subtitle {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }

  &__icon {
    width: spacing(5);
  }

  &__icon-background {
    width: spacing(4);
    height: spacing(4);
    border-radius: 100%;
    opacity: 0.5;
    background-color: $colorOffWhite;
    padding: spacing(1);
  }

  &__title {
    width: spacing(10);
  }

  &__title,
  &__subtitle {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }
}
