$component: .social-icons;
$component  {

  &__frame {}

  &__container {}

  &__list {
    line-height: 1;
    list-style-type: none;
  }

  &__item {
    display: inline-block;
    padding-right: spacing(1);
    vertical-align: middle;
    line-height: 2;
  }

  &__action {
    line-height: 1;
    width: spacing(2);
    height: spacing(2);
    display: block;
    color: currentColor;
  }
}