$parent: .header;
$component: $(parent)-burger;
$component  {
  @mixin button-reset;
  width: spacing(4);
  height: spacing(4);
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: rotate(0);
  transition-duration: resolve($transitionDuration * 2)ms;
  transition-timing-function: $transitionEaseOutExpo;

  &__container {
    width: spacing(2.5);
    height: spacing(2);
    position: relative;
    display: block;
  }

  $(parent)--is-drawer-open & {
    transform: rotate(135deg);
  }

  &--top {
    color: $colorWhite;
  }

  &__bar {
    width: 100%;
    height: 1px;
    background-color: currentColor;
    position: absolute;
    display: block;
  }

  &__bar--top {
    top: 3px;
    opacity: 1;
    transition-duration: resolve($transitionDuration * 2);
    transition-timing-function: $transitionEaseOutExpo;

    $(parent)--is-drawer-open & {
      top: 10px;
      opacity: 0;
      transition-duration: $transitionDuration;
    }
  }

  &__bar--left-diagonal,
  &__bar--right-diagonal {
    top: 10px;
    transform-origin: center center;
  }

  &__bar--right-diagonal {
    transform: rotate(0);
    transition-duration: resolve($transitionDuration * 2)ms;
    transition-timing-function: $transitionEaseOutExpo;

    $(parent)--is-drawer-open & {
      transform: rotate(90deg);
      transition-duration: resolve($transitionDuration)ms;
    }
  }

  &__bar--bottom {
    top: 17px;
    opacity: 1;
    transition-duration: resolve($transitionDuration * 2)ms;
    transition-timing-function: $transitionEaseOutExpo;

    $(parent)--is-drawer-open & {
      top: 10px;
      opacity: 0;
      transition-duration: resolve($transitionDuration)ms;
    }
  }
}
