$component: .button;
$component  {
  display: inline-block;
  vertical-align: middle;
  border: none;
  cursor: pointer;

  &__title {
    display: block;
    text-align: center;
  }

  &--reset {
    @mixin button-reset;
  }

  &--newsletter-signup-form {
    padding: spacing(1);
    cursor: pointer;
  }

  &--default {
    line-height: spacing(4);
    border-radius: spacing(0.8);
    padding: 0 spacing(4);
    display: inline-block;
    vertical-align: middle;
    min-width: spacing(20);
    border: none;
    cursor: pointer;

    [data-theme="carillon"] &,
    &[data-theme="carillon"] {
      color: $colorWhite;
      background-color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] &,
    &[data-theme="waldhaus"] {
      color: $colorWhite;
      background-color: $waldhausYellowMetal;
    }
  }

  &--header {
    line-height: spacing(4);
    border-radius: spacing(0.8);
    display: inline-block;
    vertical-align: middle;
    min-width: 100%;
    border: none;
    cursor: pointer;

    [data-theme="carillon"] &,
    &[data-theme="carillon"] {
      color: $colorWhite;
      background-color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] &,
    &[data-theme="waldhaus"] {
      color: $colorWhite;
      background-color: $waldhausYellowMetal;
    }
  }

  &--feature {
    line-height: spacing(3.8);
    border-radius: spacing(0.8);
    padding: 0 spacing(4);
    display: inline-block;
    vertical-align: middle;
    min-width: spacing(20);
    border: none;
    cursor: pointer;
    width: 100%;
    border: 2px solid;

    @mixin breakpoint $breakpointTablet {
      min-width: spacing(20);
      width: auto;
    }

    [data-theme="carillon"] &,
    &[data-theme="carillon"] {
      color: $colorWhite;
      background-color: $carillonRiverBed;
      border-color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] &,
    &[data-theme="waldhaus"] {
      color: $colorWhite;
      background-color: $waldhausYellowMetal;
      border-color: $waldhausYellowMetal;
    }

    &-alt {

      [data-theme="carillon"] &,
      &[data-theme="carillon"] {
        color: $carillonRiverBed;
        background-color: transparent;
        border-color: $carillonRiverBed;
      }

      [data-theme="waldhaus"] &,
      &[data-theme="waldhaus"] {
        color: $waldhausYellowMetal;
        background-color: transparent;
        border-color: $waldhausYellowMetal;
      }

      &-white {
        [data-theme="carillon"] &,
        &[data-theme="carillon"] {
          color: $colorWhite;
          border-color: $colorWhite;
        }
      }
    }

    &-inverted {

      [data-theme="carillon"] &,
      &[data-theme="carillon"] {
        color: $carillonRiverBed;
        background-color: $carillonCasper;
        border-color: $carillonCasper;
      }

      [data-theme="waldhaus"] &,
      &[data-theme="waldhaus"] {
        color: $colorWhite;
        background-color: $waldhausTundora;
        border-color: $waldhausTundora;
      }
    }

    &-white {
      border-radius: 0;

      [data-theme="carillon"] &,
      &[data-theme="carillon"] {
        color: $carillonRiverBed;
        background-color: $colorWhite;
        border-color: $colorWhite;
      }

      [data-theme="waldhaus"] &,
      &[data-theme="waldhaus"] {
        color: $waldhausYellowMetal;
        background-color: $colorWhite;
        border-color: $colorWhite;
      }
    }

    &-widget {
      border-radius: 0;
      line-height: 1.2;
      padding: spacing(0.625) 0;

      [data-theme="carillon"] &,
      &[data-theme="carillon"] {
        color: $carillonDoveGrey;
        background-color: $colorWhite;
        border-color: $colorWhite;
      }

      [data-theme="waldhaus"] &,
      &[data-theme="waldhaus"] {
        color: $waldhausDoveGrey;
        background-color: $colorWhite;
        border-color: $colorWhite;
      }
    }
  }

  &--cookie-banner {
    line-height: spacing(3);
    border-radius: spacing(0.8);
    padding: 0 spacing(2);
    display: inline-block;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    border: 2px solid;

    [data-theme="carillon"] &,
    &[data-theme="carillon"] {
      color: $carillonRiverBed;
      background-color: $colorWhite;
      border-color: $colorWhite;
    }

    [data-theme="waldhaus"] &,
    &[data-theme="waldhaus"] {
      color: $waldhausYellowMetal;
      background-color: $colorWhite;
      border-color: $colorWhite;
    }
  }

  &--group {
    position: relative;
    line-height: spacing(4);
    display: inline-block;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;

    [data-theme="carillon"] &,
    &[data-theme="carillon"] {
      color: $colorWhite;
      background-color: $carillonRiverBed;

      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: -1px;
          width: 1px;
          height: 100%;
          background-color: $colorOffWhite;
          opacity: 0.2;
          top: 0;
          z-index: 1;
        }
      }
    }

    [data-theme="waldhaus"] &,
    &[data-theme="waldhaus"] {
      color: $colorWhite;
      background-color: $waldhausYellowMetal;

      &:not(:last-child) {
        border-right: 1px solid $waldhausMercury;
      }
    }
  }

  &--panel-hero {
    line-height: spacing(4);
    border-radius: spacing(0.8);
    padding: 0 spacing(4);
    display: inline-block;
    vertical-align: middle;
    min-width: spacing(20);
    border: none;
    cursor: pointer;

    @mixin breakpoint $breakpointTablet {
      min-width: spacing(20);
      padding: 0 spacing(2);
      width: auto;
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding: 0 spacing(4);
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
      background-color: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
      background-color: $colorWhite;
    }
  }

  &--menu-card {
    line-height: spacing(3);
    border-radius: spacing(0.8);
    padding: 0 spacing(3);
    display: inline-block;
    vertical-align: middle;
    min-width: spacing(12);
    border: none;
    cursor: pointer;

    @mixin breakpoint $breakpointTablet {
      width: auto;
    }

    [data-theme="carillon"] & {
      color: $colorWhite;
      background-color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $colorWhite;
      background-color: $waldhausYellowMetal;
    }
  }

  &--card {
    line-height: spacing(3);
    border-radius: spacing(0.8);
    padding: 0 spacing(3);
    display: inline-block;
    vertical-align: middle;
    min-width: spacing(12);
    border: none;
    cursor: pointer;
    border: 2px solid;

    @mixin breakpoint $breakpointTablet {
      width: auto;
    }

    [data-theme="carillon"] & {
      color: $colorWhite;
      background-color: $carillonRiverBed;
      border-color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $colorWhite;
      background-color: $waldhausYellowMetal;
      border-color: $waldhausYellowMetal;
    }
  }

  &--card-alt {
    line-height: spacing(3);
    border-radius: spacing(0.8);
    padding: 0 spacing(3);
    display: inline-block;
    vertical-align: middle;
    min-width: spacing(12);
    border: none;
    cursor: pointer;
    border: 2px solid;

    @mixin breakpoint $breakpointTablet {
      width: auto;
    }

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
      background-color: $colorWhite;
      border-color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
      background-color: $colorWhite;
      border-color: $waldhausYellowMetal;
    }
  }

  &--sub-navigation {
    background-color: transparent;
    border: none;
  }
}
