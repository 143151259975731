$component: .call-to-action-block;
$component  {

  &__frame {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-left: spacing(4);
      padding-right: spacing(4);
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(8);
      padding-right: spacing(8);
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopXLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
      padding-bottom: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    @mixin breakpoint $breakpointTabletLandscape {
      text-align: center;
    }
    @mixin breakpoint $breakpointDesktopXLarge {
      max-width: $containerXxlarge;
    }
  }

  &__ctas {
    margin-left: spacing(-0.5);
    margin-right: spacing(-0.5);
    padding-top: spacing(2);
  }

  &__cta {
    display: inline-block;
    padding: 0 spacing(0.5) spacing(1) spacing(0.5);
    @mixin uptoBreakpoint $breakpointMobile {
      width: 100%;
    }
  }
}
