$component: .button-group;
$component  {
  overflow: hidden;
  border-radius: spacing(0.8);
  box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);

  &__container {
    display: flex;
  }
}
