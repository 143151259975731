$component: .language-switcher;
$component  {
  &__container {
  }

  &__select-wrapper {
    position: relative;
    width: spacing(4);
    overflow: hidden;
    text-align: left;
  }

  &__caret {
    position: absolute;
    top: spacing(2);
    right: spacing(0.5);
    height: spacing(1);
    width: spacing(1);
    color: currentColor;
    margin-top: spacing(-0.5);
    pointer-events: none;
    opacity: 0.5;
    line-height: 0;
  }

  &__select {
    cursor: pointer;
    color: currentColor;
    background-color: transparent;
    height: spacing(4);
    line-height: spacing(4);
    border: none;
    width: 150%;
    appearance: none;
    -webkit-appearance: none;

    &--custom {
      display: none;
      &__dropdown {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transition: all 0.5s ease;
        margin-top: 1rem;
        left: 0;
        bottom: 0;
        background-color: $colorWhite;
        color: $carillonDoveGrey;
        display: none;
        line-height: 15px;
        &__item {
          list-style-type: none;
          width:100%;
          display: inline-block;
          &:hover {
            background-color: rgba($carillonHippieBlue, 0.25);
          }
          a {
            width: 100%;
            display: inline-block;
          }
        }
        $(component)__select--custom:hover & {
          visibility: visible;
          opacity: 1;
          display: block;
        }
      }
    }

    @media (hover: hover) {
      &--custom {
        display: block;
      }
      &--native{
        @mixin screen-reader-only;
        &:focus {
          position: unset;
          width: unset;
          height: unset;
          margin: unset;
          padding: unset;
          overflow: unset;
          clip: unset;
          border: unset;
        }
      }
      &--native:focus + &--custom {
        display: none;
      }
    }

  }

  &__select--custom:hover {

  }

  &--contact-bar,
  &--header-scroll,
  &--header-drawer {
    $(component)__select {

      [data-theme="carillon"] & {
        color: $carillonDoveGrey;
      }

      [data-theme="waldhaus"] & {
        color: $waldhausDoveGrey;
      }
    }
  }

  &--header-top {
    $(component)__select {
      &--custom {
        &__dropdown {
          width: 150%;
        }
      }

      > option {

        [data-theme="carillon"] & {
          color: $carillonDoveGrey;
        }

        [data-theme="waldhaus"] & {
          color: $waldhausDoveGrey;
        }
      }
    }
  }
}

@media (hover: hover) {
  $(component)--custom{
    display: block;
  }
$(component)--native:focus + $(component)--custom {
  display: none;
}
}
