.promo-bar {
  height: 100%;
  max-height: spacing(16);
  background-color: $carillonRiverBed;
  will-change: max-height;
  transition: max-height .3s linear;
  .header-scroll & {
    display: none;
  }
  .header--has-scroll-navigation .header-scroll &,
  .header--has-sub-navigation .header-scroll & {
    display: block;
  }
  .header--is-scrolled & {
    max-height: 0;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: spacing(1) spacing(4);
  }
  .wysiwyg--promo-bar .wysiwyg__body {
    color: $colorWhite;
    text-align: center;
    font-size: px-to-rem(14);
  }

  .wysiwyg--promo-bar--desktop {
    display: none;
  }

  @mixin breakpoint $breakpointTablet {
    .wysiwyg--promo-bar--mobile {
      display: none;
    }
    .wysiwyg--promo-bar--desktop {
      display: block;
    }
    .wysiwyg--promo-bar .wysiwyg__body {
      text-align: left;
    }
  }
  @mixin breakpoint $breakpointDesktop {

  }
}
