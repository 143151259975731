.turbolinks-progress-bar {
  height: 3px;
  background-color: $colorAlabaster;
  box-shadow: 0 0 5px 0 rgba($colorOffBlack, 0.1);

  [data-theme="carillon"] & {
    background-color: $carillonHippieBlue;
  }

  [data-theme="waldhaus"] & {
    background-color: $waldhausYellowMetal;
  }
}
