$component: .utility-panel-block;
$component  {
  position: relative;

  @mixin breakpoint $breakpointTabletLandscape {
    @mixin fill-parent;
  }

  &__container {
    position: relative;
    padding-bottom: aspect-ratio(1,1.25);

    @mixin breakpoint $breakpointMobile {
      padding-bottom: aspect-ratio(2,3);
    }

    @mixin breakpoint $breakpointTablet {
      padding-bottom: aspect-ratio(2,4);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      @mixin fill-parent;
      padding: 0;
    }
  }

  &__background {
    @mixin fill-parent;
  }


  &__background__media,
  &__background__google-map {
    @mixin fill-parent;
  }

  &__foreground {
    position: relative;
    min-height: spacing(36);
    height: auto;
    display: flex;
    width: 100%;
    @mixin breakpoint $breakpointTabletLandscape {
      @mixin fill-parent;
    }
  }

  &__foreground__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(2.5);

    flex-direction: column;
    height: 100%;
    width: 100%;

    @mixin breakpoint $breakpointTablet {
      padding: spacing(10) spacing(2.5) spacing(6) spacing(2.5);
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__foreground__title {
    padding-bottom: spacing(2);
    text-align: center;
    color: $colorWhite;
  }

  &__foreground__cta {
    display: block;
    width: 100%;
    text-align: center;
  }
}
