$component: .menu-card;
$component  {
  width: 100%;

  &__frame {}

  &__container {}

  &__title {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__subtitle {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__body {

    &:not(:last-child) {
      padding-bottom: spacing(2);
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__cta {
    margin-top: spacing(2);
  }
}
