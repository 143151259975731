$component: .cookie-banner;
$component  {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zIndexGlobalCookieBanner;
  color: $colorWhite;
  display: none;

  &.is-active {
    display: block;
  }

  &__frame {
    padding: spacing(2) spacing(2.5);
    @mixin breakpoint $breakpointTablet {
      padding: spacing(2) spacing(4);
    }
    [data-theme="carillon"] & {
      background-color: $carillonRiverBed;
    }
    [data-theme="waldhaus"] & {
      background-color: $waldhausYellowMetal;
    }
  }

  &__container {
  }

  &__title {
    padding-bottom: spacing(1);
    @mixin breakpoint $breakpointTablet {
      padding-bottom: 0;
      display: inline-block;
      padding-right: spacing(1);
      vertical-align: middle;
      max-width: 50%;
    }

    a {
      text-decoration: underline;
    }
  }

  &__action {
    @mixin breakpoint $breakpointTablet {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
