$component: .share-block;
$component  {

  &__frame {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    padding-bottom: spacing(2.5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-left: spacing(4);
      padding-right: spacing(4);
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(8);
      padding-right: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
    }
  }

  &__container {
    position: relative;
    overflow: hidden;
    max-width: $containerLarge;
    margin: 0 auto;
    text-align: center;
    @mixin breakpoint $breakpointTabletLandscape {
      text-align: right;
    }
  }
}
