$global: .action;
$global  {
  color: currentColor;
  line-height: inherit;

  &--underline {

    $(global)__title {
      position: relative;

      &:after {
        @mixin transition width, resolve($transitionDuration * 6), $transitionEaseOutExpo;
        display: block;
        content: '';
        width: 0;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    @mixin hover-state {
      $(global)__title {
        &:after {
          transition-duration: resolve($transitionDuration * 1.25)ms;
          width: 100%;
        }
      }
    }

    &.is-active {

      $(global)__title {
        &:after {
          width: 100%;
        }
      }
    }
  }

  &--underline-subnavigation {

    $(global)__title {
      position: relative;

      &:after {
        @mixin transition width, resolve($transitionDuration * 6), $transitionEaseOutExpo;
        display: block;
        content: '';
        width: 0;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    @mixin breakpoint $breakpointTabletLandscape {

      @mixin hover-state {
        $(global)__title {
          &:after {
            transition-duration: resolve($transitionDuration * 1.25)ms;
            width: 100%;
          }
        }
      }
    }

    &.is-active {

      $(global)__title {
        &:after {
          width: 100%;
        }
      }
    }
  }

  &__title {
    position: relative;
    display: block;
  }
}
