$component: .share;
$component  {

  &__frame {
  }

  &__container {
  }

  &__flipper {
    cursor: pointer;
    text-align: center;
    width: 100%;
    width: spacing(10);
    display: inline-block;
    height: spacing(4);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }

    $(component)--gallery-lightbox & {
      height: spacing(6);
    }
  }

  &__flipper__track {
    will-change: transform;
    transform-property: transform;
    transition-duration: resolve($transitionDuration * 3)ms;
    transition-timing-function: $transitionEaseOutExpo;

    .is-active & {
      transform: translateY(-50%);
    }
  }

  &__flipper__block {
    height: spacing(4);
    line-height: spacing(4);

    $(component)--gallery-lightbox & {
      height: spacing(6);
      line-height: spacing(6);
    }
  }

  &__list {
    line-height: spacing(4);
    list-style-type: none;

    $(component)--gallery-lightbox & {
      line-height: spacing(6);
    }
  }

  &__item {
    display: inline-block;
    padding-right: spacing(1);
    vertical-align: middle;
    line-height: 2;
  }

  &__action {
    line-height: 1;
    width: spacing(2);
    height: spacing(2);
    display: block;
    color: currentColor;
  }
}
