* {
  box-sizing:border-box;
}

html {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  min-width: 320px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

body {
  min-height: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
figure,
b {
  margin: 0;
  padding: 0;
  font-weight: normal;

  &:last-child{
    margin-bottom:0;
    padding-bottom: 0;
  }
}

p {
  line-height: 1.5;
  padding-bottom: 1.5em;

  a {
    color: currentColor;
  }
}

img,
svg {
  vertical-align: bottom;
}

a {
  display: inline;
  color: currentColor;
  text-decoration: none;
}

::selection {
  background-color: rgba(0, 0, 0, 0.15);
}


.no-transitions {
  * {
    transition: none !important;
  }
}
