$component: .booking-calendar;
$component  {

  &__header {

    [data-theme="carillon"] & {
    color: $carillonRiverBed;
  }

  [data-theme="waldhaus"] & {
    color: $waldhausYellowMetal;
  }
    
    @mixin breakpoint $breakpointTablet {
      display: none;
    }

    &__container {
      padding-left: spacing(2.5);
      padding-right: spacing(2.5);
      display: flex;
      align-items: center;
      background-color: $colorWhite;
      box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);
      height: spacing(6);
      position: relative;
      z-index: $zIndexLocalContext;
      @mixin breakpoint $breakpointTablet {
        padding-left: spacing(4);
        padding-right: spacing(4);
        height: spacing(8);
      }
      @mixin breakpoint $breakpointTabletLandscape {
        height: spacing(10);
      }
    }

    &__left {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      @mixin breakpoint $breakpointDesktopLarge {
        display: none;
      }
    }

    &__center {
      flex-grow: 1;
    }

    &__center__items {
      display: block;
      margin: 0 auto;
      @mixin breakpoint $breakpointDesktopLarge {
        display: flex;
        align-items: center;
      }
    }

    &__center__item {
      display: block;

      &--logo {
        @mixin breakpoint $breakpointDesktopLarge {
          width: spacing(32);
        }
      }
    }

    &__right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      @mixin breakpoint $breakpointDesktopLarge {
        display: none;
      }
    }

    &__right__items {
      display: inline-flex;
      margin-left: spacing(-2);
      margin-right: spacing(-2);

      @mixin breakpoint $breakpointDesktopLarge {
        margin-left: spacing(-1.25);
        margin-right: spacing(-1.25);
      }
    }

    &__right__item {
      display: inline-block;
      vertical-align: middle;
      padding-left: spacing(1.25);
      padding-right: spacing(1.25);

      &--close {
        display: inline-block;
        @mixin breakpoint $breakpointTablet {
          display: none;
        }
      }
    }

    &__close {
      width: spacing(4);
      height: spacing(4);
      color: $colorWhite;

      [data-theme="carillon"] & {
        color: $carillonRiverBed;
      }

      [data-theme="waldhaus"] & {
        color: $waldhausYellowMetal;
      }

      &__link {
        display: flex;
        line-height: 0;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
      }

      &__icon {
        display: block;
        width: spacing(2.5);
      }
    }

    &__brand {
      width: 100%;
    }

    &__logo {
      line-height: 1;
      display: block;
      width: spacing(16);
      color: currentColor;
      margin: 0 auto;
      @mixin breakpoint $breakpointTabletLandscape {
        width: spacing(20);
      }
      @mixin breakpoint $breakpointDesktopLarge {
        width: spacing(20);
        margin-left: 0;
      }

      &--waldhaus {
        width: spacing(10);

        @mixin breakpoint $breakpointTablet {
          width: spacing(12);
          opacity: 1;
        }

        @mixin breakpoint $breakpointTabletLandscape {
          width: spacing(14);
          opacity: 1;
        }
        @mixin breakpoint $breakpointDesktopLarge {
          width: spacing(16);
        }
      }
    }
  }
}
