$component: .inline-call-to-action;
$component  {
  display: inline-block;
  vertical-align: middle;
  border: none;
  cursor: pointer;

  [data-theme="carillon"] & {
    color: $carillonDoveGrey;
  }

  [data-theme="waldhaus"] & {
    color: $waldhausDoveGrey;
  }

  &__icon {
    width: spacing(2);
    display: inline-block;
    line-height: 0;
    vertical-align: middle;
    color: currentColor;

    [data-theme="carillon"] & {
      color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__title {
    display: inline-block;
    color: currentColor;
  }

  &--hero {
    [data-theme="carillon"] & {
      color: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      color: $colorWhite;
    }

    $(component)__icon {
      color: $colorWhite;
    }
  }

  &--header-top-phone,
  &--header-scroll-phone,
  &--header-drawer-phone {
    display: flex;
    line-height: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: currentColor;

    $(component)__title {
      display: none;
    }

    $(component)__icon {
      display: block;
      width: spacing(2.5);
      color: currentColor;
    }
  }

  &--header-top-phone {
    [data-theme="carillon"] & {
      color: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      color: $colorWhite;
    }
  }
}
