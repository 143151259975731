$component: .skip-to-main-content-link;
$component  {
  position: absolute;
  top: 0;
  left: 0;
  padding: spacing(1) spacing(2);
  color: $colorOffBlack;
  background-color: $colorWhite;
  box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);
  pointer-events: none;
  left: -999px;
  z-index: $zIndexAbsoluteBottom;

  &:focus,
  &:active {
    left: 0;
    display: block;
    z-index: $zIndexGlobalSkipToContentLink;
  }

  &__title {
  }
}
