$parent: .header;
$component: $(parent)-top;
$component  {
  z-index: $zIndexGlobalHeader;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  [data-theme="carillon"] & {
    color: $colorWhite;
  }

  [data-theme="waldhaus"] & {
    color: $colorWhite;
  }

  $(parent)--has-sub-navigation & {
    display: none;
  }

  $(parent)--has-scroll-navigation & {
    display: none;
  }

  &__frame {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    @mixin breakpoint $breakpointTablet {
      padding-left: spacing(4);
      padding-right: spacing(4);
    }
    @mixin breakpoint $breakpointDesktopLarge {
      padding-top: spacing(2);
      padding-bottom: spacing(2);
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    height: spacing(6);
    @mixin breakpoint $breakpointTablet {
      height: spacing(8);
    }
    @mixin breakpoint $breakpointTabletLandscape {
      height: spacing(10);
    }
    @mixin breakpoint $breakpointDesktopLarge {
      align-items: flex-end;
      height: auto;
    }
  }

  &__left,
  &__right {
    width: 50%;
    @mixin breakpoint $breakpointDesktopLarge {
      width: spacing(20);
      padding-bottom: spacing(0.5);
    }
  }

  &__left,
  &__center,
  &__right {
    display: flex;
  }

  &__left {
    text-align: left;
    justify-content: flex-start;
  }

  &__center {
    flex-grow: 1;
    display: block;
  }

  &__right {
    justify-content: flex-end;
  }

  &__burger {
    width: spacing(4);
    height: spacing(4);
  }

  &__language-switcher {
    width: spacing(4);
    height: spacing(4);
    color: $colorWhite;
  }

  &__phone-number {
    width: spacing(4);
    height: spacing(4);
    color: $colorWhite;

    &__link {
      display: flex;
      line-height: 0;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__icon {
      display: block;
      width: spacing(2.5);
    }
  }

  &__primary-cta,
  &__secondary-cta,
  &__booking-widget-cta {
    width: spacing(15);
    @mixin breakpoint $breakpointDesktopLarge {
      width: spacing(20);
    }
  }

  &__secondary-cta {
    @mixin breakpoint $breakpointDesktopLarge {
      display: none;
    }
  }

  &__booking-widget {
    display: none;
    @mixin breakpoint $breakpointDesktopLarge {
      display: block;
    }
  }

  &__booking-widget-cta {
    display: none;
    @mixin breakpoint $breakpointDesktopLarge {
      display: block;
    }
  }

  &__brand {
    display: block;
    text-align: center;
    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(1);
    }
  }

  &__marriott-logo {
    display: inline-block;
    width: 100%;
  }

  &__logo {
    line-height: 1;
    display: block;
    width: spacing(16);
    color: currentColor;
    margin: 0 auto;

    $(parent)--is-homepage & {
      display: none;
      @mixin breakpoint $breakpointTabletLandscape {
        display: block;
      }
      &--marriott {
        display: block;
        width: spacing(20);
        @mixin breakpoint $breakpointDesktopLarge {
          display: none;
        }
      }
      &--waldhaus {
        display: none;
        @mixin breakpoint $breakpointDesktopLarge {
          display: block;
        }
      }
    }

    @mixin breakpoint $breakpointTabletLandscape {
      width: spacing(20);
      opacity: 1;
    }
    @mixin breakpoint $breakpointDesktopLarge {
      width: spacing(26);
    }

    &--waldhaus,
    &--chalet-belmont {
      width: spacing(10);

      @mixin breakpoint $breakpointTablet {
        width: spacing(12);
        opacity: 1;
      }

      @mixin breakpoint $breakpointTabletLandscape {
        width: spacing(14);
        opacity: 1;
      }
      @mixin breakpoint $breakpointDesktopLarge {
        width: spacing(16);
      }
    }
  }

  &__center__navigation {
    display: none;
    @mixin breakpoint $breakpointDesktopLarge {
      display: block;
      text-align: center;
    }
  }

  &__navigation {
    display: inline-block;
  }

  &__navigation__list {
    list-style-type: none;
  }

  &__navigation__item {
    display: inline-block;
  }

  &__navigation__action {
    display: block;
    padding: spacing(0.8);
    color: $colorWhite;
    line-height: 2;
  }

  &__left {
    text-align: left;

    &__marriott {
      display: none;
      @mixin breakpoint $breakpointDesktopLarge {
        display: block;
        position: absolute;
        max-width: 200px;
        text-align: center;
        width: 100%;
        left: 0;
        top: 20px;
      }
    }

    &__items {
      margin-left: spacing(-2);
      margin-right: spacing(-2);
      display: inline-flex;

      @mixin breakpoint $breakpointDesktopLarge {
        display: block;
        margin-left: spacing(-1.25);
        margin-right: spacing(-1.25);
      }
    }

    &__item {
      display: inline-block;
      vertical-align: middle;
      padding-left: spacing(1.25);
      padding-right: spacing(1.25);

      &--burger {
        display: inline-block;
        @mixin breakpoint $breakpointDesktopLarge {
          display: none;
        }
      }

      &--primary-cta {
        display: none;
        @mixin breakpoint $breakpointTablet {
          display: inline-block;
        }
      }
    }
  }

  &__center {
    text-align: center;

    &__items {
      display: block;
      margin: 0 auto;
    }

    &__item {
      display: block;

      &--navigation {
        display: none;
        @mixin breakpoint $breakpointDesktopLarge {
          display: block;
        }
      }
    }
  }

  &__right {
    text-align: right;

    &__items {
      display: inline-flex;
      margin-left: spacing(-2);
      margin-right: spacing(-2);

      @mixin breakpoint $breakpointDesktopLarge {
        margin-left: spacing(-1.25);
        margin-right: spacing(-1.25);
      }
    }

    &__item {
      display: inline-block;
      vertical-align: middle;
      padding-left: spacing(1.25);
      padding-right: spacing(1.25);

      &--secondary-cta {
        display: none;
        @mixin breakpoint $breakpointTablet {
          display: inline-block;
        }
      }

      &--booking-widget-cta {
        display: none;
        @mixin breakpoint $breakpointTablet {
          display: inline-block;
        }
      }

      &--language-switcher {
        display: none;
        @mixin breakpoint $breakpointTablet {
          display: inline-block;
        }
        @mixin breakpoint $breakpointDesktopLarge {
          display: none;
        }
      }

      &--phone-number {
        display: inline-block;
        @mixin breakpoint $breakpointTablet {
          display: none;
        }
      }
    }
  }
}
