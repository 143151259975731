$component: .inline-svg-icon;
$component  {
  display: inline-block;
  position: relative;
  width: 100%;
  line-height: 1;

  &__container {
    display: block;
    padding-bottom: 100%;

    > svg {
      pointer-events: none;
      fill: currentColor;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

