$component: .utility-text-block;
$component  {

  &__wysiwyg {
    &:not(:last-child) {
      padding-bottom: spacing(2);

      @mixin breakpoint $breakpointTablet {
        padding-bottom: spacing(4);
      }
    } 
  }
}
