$component: .media;
$component {

  &__container {
    position: relative;
    overflow: hidden;
  }

  &--fillparent {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    $(component)__container {
      @mixin fill-parent;
    }
  }

  &--position-bottom {
    $(component)__object {
      background-position: bottom;
    }
  }

  &:not(&--fillparent) {

    $(component)__aspect {
      padding-bottom: aspect-ratio(2,3);
    }
  }

  &__aspect {
    overflow: hidden;
    height: 0;

    $(component)--fillparent & {
      @mixin fill-parent;
      width: 100%;
      height: 100%;
    }
  }

  &__background,
  &__object {
    @mixin fill-parent;
  }

  &__background {
    background-color: $colorOffWhite;
  }

  &__object {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__hidden {
    @mixin screen-reader-only;
    display: none;

    > * {
      @mixin screen-reader-only;
      display: none;
    }
  }

  &__video {
    height: 100%;
    width: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
    }
  }

  &__video-description {
     clip: rect(1px, 1px, 1px, 1px);
     clip-path: inset(50%);
     height: 1px;
     width: 1px;
     margin: -1px;
     overflow: hidden;
     padding: 0;
     position: absolute;
  }

  &__object {
    opacity: 0;
    will-change: opacity;
    transition-property: opacity;
    transition-duration: resolve($transitionDuration * 4)ms;
    transition-timing-function: $transitionEaseOutExpo;

    .is-loaded & {
      opacity: 1;
    }
  }

  &__figcaption {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: spacing(1);
    text-align: center;
    color: $colorWhite;
    pointer-events: none;
    background-image: linear-gradient(transparent 0%, transparent 20%, rgba($colorOffBlack, 0.5) 80%, rgba($colorOffBlack, 0.65) 100%);
    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(1) spacing(2);
      text-align: left;
      opacity: 0;
    }

    .is-loaded & {
      opacity: 1;
    }
  }
}
