$parent: .header;
$component: $(parent)-gradient;
$component  {
  background-image: linear-gradient(rgba($colorOffBlack, 0.25) 0%,  rgba($colorOffBlack, 0) 100%);
  z-index: $zIndexGlobalHeader;
  height: spacing(10);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
