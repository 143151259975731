
@font-face {
  font-family: "FreightDisp Pro Book Italic-Black";
  src: url("/fonts/FreightDispProBookItalic/FreightDispProBookItalic.woff2") format("woff2"),
       url("/fonts/FreightDispProBookItalic/FreightDispProBookItalic.woff") format("woff"),
       url("/fonts/FreightDispProBookItalic/FreightDispProBookItalic.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mark Pro";
  src: url("/fonts/MarkPro/MarkPro.woff2") format("woff2"),
       url("/fonts/MarkPro/MarkPro.woff") format("woff"),
       url("/fonts/MarkPro/MarkPro.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mark Pro Bold";
  src: url("/fonts/MarkProBold/MarkPro-Bold.woff2") format("woff2"),
       url("/fonts/MarkProBold/MarkPro-Bold.woff") format("woff"),
       url("/fonts/MarkProBold/MarkPro-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
