$calendarWidth: 320px;
$calendarWidthDesktop: 400px;

$scope: .booking-calendar;
$component: .flatpickr;
$scope $component  {
  position: relative;

  &-calendar {
    width: 100% !important;
    box-shadow: none;
    max-width: $calendarWidth;
    overflow: hidden;
    display: inline-block;
    top: 0;
    position: relative;

    &:before,
    &:after {
      display: none;
    }

    @mixin breakpoint $breakpointTabletLandscape {
      max-width: $calendarWidthDesktop;
    }

    &.multiMonth {

      @mixin breakpoint $breakpointTabletLandscape {
        max-width: resolve($calendarWidthDesktop * 2);
        height: 100%;
      }

      @mixin breakpoint $breakpointTabletLandscape {
        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background-color: rgba($colorBlack, 0.1);
          position: absolute;
          left: calc(50% - 1px);
          margin: 0;
          border: none !important;
          top: 0;
        }
      }
    }

    .dayContainer {
      padding-top: spacing(0.2);
      width: 100%;
      @mixin breakpoint $breakpointTabletLandscape {
        padding-left: spacing(2);
        padding-right: spacing(2);
      }
    }
  }

  &-weekdaycontainer {
    @mixin breakpoint $breakpointTabletLandscape {
        padding-left: spacing(2);
        padding-right: spacing(2);
      }
  }

  &-months {
    white-space: nowrap;
    width: 100%;
    display: block;

    @mixin breakpoint $breakpointTabletLandscape {
      display: flex;
    }
  }

  &-month {
    padding: spacing(2);
    height: auto;
    display: inline-block;
    width: 100%;

    @mixin breakpoint $breakpointTabletLandscape {
      width: auto;
    }
  }

  &-next-month,
  &-prev-month {
    line-height: 0;
    top: 0;
    width: spacing(6);
    height: spacing(6);
    padding: spacing(2);

    &:hover {

      [data-theme="carillon"] & {
        color: $carillonRiverBed;

        svg {
          fill: $carillonRiverBed;
        }
      }

      [data-theme="waldhaus"] & {
        color: $waldhausYellowMetal;

        svg {
          fill: $waldhausYellowMetal;
        }
      }
    }

    svg {
      width: spacing(2);
      height: spacing(2);
    }
  }

  &-current-month {
    padding: 0;
    position: relative;
    height: auto;
    width: 100%;
    left: 0;

    .cur-month {
      margin-left: 0;
      padding-right: spacing(1);
      font-weight: normal;

      &:hover {
        background-color: transparent;
      }
    }

    .cur-year {
      padding: 0;
    }

    .numInputWrapper {
      width: auto;
      pointer-events: none;

      &:hover {
        background: transparent;
      }

      span {
        display: none !important;
      }
    }
  }

  &-weekdays {
    pointer-events: none;
    user-select: none;
  }

  &-weekday {
    font-size: px-to-rem(10);
    letter-spacing: px-to-em(1);
    font-weight: normal;
  }

  &-days {
    width: auto !important;
    padding-bottom: 0;
  }

  &-day {
    $size: resolve(floor(resolve($calendarWidth / 7)));
    border: none !important;
    border-color: transparent;
    height: $size;
    line-height: $size;
    width: $size;
    flex-basis: $size;
    border-radius: 100% !important;
    margin-bottom: 3px;
    font-weight: normal;
    font-size: px-to-rem(14);

    @mixin breakpoint $breakpointTabletLandscape {
      margin: 1px;
    }

    &:hover {
      border-color: $colorOffWhite;
      background: $colorOffWhite;
      background-color: $colorOffWhite;
    }

    &.selected.startRange,
    &.selected.endRange,
    &.endRange.endRange {
      border-radius: 100% !important;
    }

    .prevMonthDay,
    .nextMonthDay {
      border: none !important;
      border-color: transparent;
    }

    &.inRange {
      box-shadow: none !important;
      background: $colorOffWhite;
      background-color: $colorOffWhite;
      border-radius: 100% !important;
    }
  }

  &-calendar {
    &.multiMonth {

      $(component)-weekdaycontainer {
        width: $calendarWidth;
        @mixin breakpoint $breakpointTabletLandscape {
          width: auto;
        }
      }

      $(component)-days {
        .dayContainer {
          width: $calendarWidth;
          @mixin breakpoint $breakpointTabletLandscape {
            width: 100%;
          }

          + .dayContainer {
            box-shadow: none;
          }
        }
      }
    }
  }
}

$component {

  &-day {

    &.selected,
    &.startRange,
    &.endRange {

      [data-theme="carillon"] & {
        border-color: $carillonRiverBed;
        background: $carillonRiverBed;
        background-color: $carillonRiverBed;
        color: $colorWhite;
      }

      [data-theme="waldhaus"] & {
        border-color: $waldhausYellowMetal;
        background: $waldhausYellowMetal;
        background-color: $waldhausYellowMetal;
        color: $colorWhite;
      }
    }
  }
}
