$component: .mobile-call-to-actions;
$component  {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $zIndexGlobalMobileCallToActions;
  line-height: 1;
  @mixin breakpoint $breakpointTabletLandscape {
    display:none;
  }

  &__container {
    padding: spacing(2) spacing(2.5);
    text-align:center;
  }

  &__ctas {
    display: inline-block;
    width: 100%;
    max-width: $containerXxsmall;
  }
}
