$component: .footer;
$component  {
  position: relative;

  &[data-theme="carillon"] {
    background-color: $carillonRiverBed;
  }

  &[data-theme="waldhaus"] {
    background-color: $waldhausYellowMetal;
  }

  &__frame {
    padding: spacing(4) spacing(2.5);
    padding-bottom: resolve(spacing(4) + $offsetContactBar);

    @mixin breakpoint $breakpointTablet {
      padding: spacing(4) spacing(4) spacing(6) spacing(4);
      padding-bottom: resolve(spacing(4) + $offsetContactBar);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(8) spacing(4) spacing(8) spacing(4);
      padding-bottom: resolve(spacing(8) + $offsetContactBar);
    }

    @mixin breakpoint $breakpointDesktop {
      padding: spacing(8);
      padding-bottom: resolve(spacing(8) + $offsetContactBar);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding: spacing(10);
      padding-bottom: resolve(spacing(10) + $offsetContactBar);
    }
  }

  &__container {
    position: relative;
  }

  &__body {
    position: relative;
    @mixin breakpoint $breakpointDesktop {
      display: flex;
    }
  }

  &__left,
  &__right {
    @mixin breakpoint $breakpointDesktop {
      align-self: flex-end;
    }
    @mixin breakpoint $breakpointDesktopLarge {
      width: 50%;
    }
  }

  &__left {
    padding-bottom: spacing(4);
    @mixin breakpoint $breakpointDesktop {
      padding-bottom: 0;
      flex: 1;
    }
  }

  &__right {}

  &__brand {
    color: $colorWhite;
    max-width: spacing(4);
    padding-bottom: spacing(1);
  }

  &__newsletter {
    @mixin breakpoint $breakpointTabletLandscape {
      max-width: $containerSmall;
    }
  }

  &__newsletter__title {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      color: $colorWhite;
    }
  }

  &__social {
    color: $colorWhite;
    padding-bottom: spacing(4);
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(2);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(3);
      position: relative;
      text-align: right;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__social-marriott {
    width: 50%;
    @mixin breakpoint $breakpointTablet {
      width: auto;
    }
    @mixin breakpoint $breakpointDesktop {
      width: auto;
      padding: 0 spacing(2);
    }
  }

  &__marriott-logo {
    display: block;
    width: 120px;
  }

  &__social-icons {
    width: 50%;
    @mixin breakpoint $breakpointTablet {
      width: auto;
      padding-right: 60px;
    }
    @mixin breakpoint $breakpointDesktop {
      padding-right: 0;
      width: auto;
    }
  }

  &__navigation {
    @mixin breakpoint $breakpointDesktop {
      text-align: right;
    }
  }

  &__navigation__list {
    column-count: 2;
    list-style-type: none;
    @mixin breakpoint $breakpointTablet {
      column-count: 1;
      margin-left: spacing(-0.5);
      margin-right: spacing(-0.5);
    }
    @mixin breakpoint $breakpointDesktop {
      margin-left: spacing(-0.5);
      margin-right: spacing(-0.5);
    }
  }

  &__navigation__item {
    line-height: 2;
    @mixin breakpoint $breakpointTablet {
      display: inline-block;
      padding-right: spacing(1);
    }
  }

  &__navigation__action {

    [data-theme="carillon"] & {
      color: $colorWhite;
      fill: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      color: $colorWhite;
      fill: $colorWhite;
    }
  }

  &__aside {
    padding: spacing(2) 0;
    @mixin breakpoint $containerPhablet {
      padding: spacing(4) 0 0 0;
    }
  }

  &__affiliate-logos {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    margin-left: spacing(-0.5);
    margin-right: spacing(-0.5);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @mixin breakpoint $breakpointTablet {
      justify-content: flex-start;
    }
  }

  &__affiliate-logo, &__main-logo {
    display: inline-block;
    margin: spacing(0.5);
    width: auto;
  }

  &__affiliate-logo {
    max-width: 120px;
    width: 25%;
  }

  &__affiliate-link {
    display: block;
  }

  &__affiliate-icon, &__main-logo-icon {
    display: block;
    height: 64px;
    max-width: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__main-logo-icon {
   width: 100%;
   background-size: contain;
 }

  &[data-theme="carillon"] {
     .footer__header,
     .footer__body,
     .footer__footer {
        max-width: 960px;
        margin: 0 auto;
     }

     .footer__body,
     .footer__footer,
     .footer__navigation {
        border-top: 1px solid $colorWhite;
        margin-top: 40px;
        padding-top: 40px;
     }

     .footer__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
     }

     .footer__newsletter {
        width: 100%;
     }

     .footer__newsletter__title h1.type--h4{
       font-family: "Mark Pro", sans-serif;
       font-weight: 700;
       font-size: 0.8125rem;
       text-transform: uppercase;
       letter-spacing: 2px;
     }

     .footer__social {
       padding-bottom: 0;
     }

     .social-icons__list {
       display: flex;
       justify-content: flex-start;
       align-items: center;
     }

     .social-icons__item {
       padding: 0;
       margin-right: 15px;
     }

     .social-icons__action {
       height: 50px;
       width: 50px;
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 50%;
       background-color: $colorWhite;
       padding: 15px;

       svg path {
         fill: $carillonRiverBed !important;
       }
     }

     .footer__social-icons {
        width: auto;
     }

     .footer__navigation {
        width: 100%;
        padding-left: 5px;
     }

     .footer__navigation__list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
     }

     .footer__main-logo {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     }

     .footer__main-logo-heading {
       font-family: "Mark Pro", sans-serif;
       font-size: 0.6875rem;
       font-weight: 400;
       letter-spacing: 1px;
       line-height: 1.2673;
       text-align: center;
       text-transform: uppercase;
       color: $colorWhite;
       margin-bottom: 10px;
     }

     .footer__main-logo-link {
       height: 64px;
       width: 100%;
       max-width: 120px;
     }

     .footer__navigation__item {
        text-align: left;
        flex: 1 0 calc(50% - 10px);
        margin-right: 10px;
        padding-right: 0;

        &:nth-child(2n) {
          margin-right: 0;
        }
     }

     .footer__affiliate-logo {
        margin: 5px 20px;
     }

     .footer__affiliate-logos {
        justify-content: center;
        align-items: center;
     }

     @mixin breakpoint $breakpointTabletLandscape {

        .footer__header {
           flex-direction: row;
           justify-content: space-between;
           align-items: center;
        }

        .footer__body {
           display: flex;
           justify-content: space-between;
           align-items: center;
        }

       .footer__main-logo {
         margin-left: 0;
         margin-right: 0;
       }

         .footer__navigation {
            width: 50%;
            padding: 0;
            margin: 0;
            border: none;
         }

        .footer__navigation__item {
           text-align: left;
           flex: 1 0 33%;
           margin-right: 0;
           padding-right: 0;

           &:nth-child(3n) {
              flex: 1 0 16.5%;
           }
        }

        .footer__affiliate-logos {
          justify-content: flex-start;
        }
     }
  }
}
