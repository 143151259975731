$component: .booking-bar;
$component  {

  &__frame {
    padding-left: spacing(2.5);
    padding-bottom: spacing(5);
    padding-right: spacing(2.5);

    @mixin breakpoint $breakpointTablet {
      padding-left: spacing(4);
      padding-right: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(8);
      padding-right: spacing(8);
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
      padding-bottom: spacing(10);
    }
  }


  &__container {
    border-radius: spacing(1);
    max-width: $containerBookingBar;
    text-align: center;
    margin: 0 auto;
    padding: spacing(2);
    position: relative;

    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(2) spacing(6) spacing(4) spacing(6);
    }

    [data-theme="carillon"] & {
      background-color: $carillonRiverBed;
      color: $colorWhite;
    }

    [data-theme="waldhaus"] & {
      background-color: $waldhausYellowMetal;
      color: $colorWhite;
    }
  }

  &__direct-link {
    position: absolute;
    z-index: 25;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__header {
    border-bottom: 1px solid;
    padding-bottom: spacing(2);
    margin-bottom: spacing(2);

    [data-theme="carillon"] & {
      border-color: rgba($colorWhite, 0.25);
    }

    [data-theme="waldhaus"] & {
      background-color: gba($colorWhite, 0.25);
    }
  }

  &__body {
  }

  &__form {
  }

  &__form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @mixin breakpoint $breakpointTablet {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
    }
  }

  &__date-field {
    width: 100%;
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
    @mixin breakpoint $breakpointTablet {
      flex-grow: 1;
      max-width: spacing(32);
    }
  }

  &__adult-field {
    width: 100%;
    width: calc(50% - spacing(1));
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
    @mixin breakpoint $breakpointTablet {
      width: spacing(8);
    }
  }

  &__child-field {
    width: 100%;
    width: calc(50% - spacing(1));
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
    @mixin breakpoint $breakpointTablet {
      width: spacing(8);
    }
  }

  &__field-label {
    text-align: center;
    padding-bottom: spacing(1);
    @mixin breakpoint $breakpointTablet {
      text-align: left;
    }
  }

  &__field-input {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__action {
    @mixin uptoBreakpoint $breakpointTablet {
      padding-top: spacing(1);
      width: 100%;
    }
  }
}
