$parent: .header;
$component: $(parent)-scroll;
$component  {
  z-index: $zIndexGlobalHeader;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  transform: translateY(-100%);
  transition-duration: resolve($transitionDuration * 2)ms;
  transition-timing-function: $transitionEaseOutExpo;

  $(parent)--has-sub-navigation & {
    transform: translateY(0);
  }

  $(parent)--has-scroll-navigation & {
    transform: translateY(0);
  }

  $(parent)--is-scrolled & {
    transform: translateY(0);
    transition-duration: resolve($transitionDuration * 1.5)ms;
  }

  &:focus-within {
    transform: translateY(0);
    transition-duration: resolve($transitionDuration * 1.5)ms;
  }

  [data-theme="carillon"] & {
    color: $carillonRiverBed;
  }

  [data-theme="waldhaus"] & {
    color: $waldhausYellowMetal;
  }

  &__container {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    display: flex;
    align-items: center;
    background-color: $colorWhite;
    box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);
    height: spacing(6);
    position: relative;
    z-index: $zIndexLocalContext;
    @mixin breakpoint $breakpointTablet {
      padding-left: spacing(4);
      padding-right: spacing(4);
      height: spacing(8);
    }
    @mixin breakpoint $breakpointTabletLandscape {
      height: spacing(10);
    }
  }

  &__left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    @mixin breakpoint $breakpointScrollHeader {
      display: none;
    }
  }

  &__left__items {
    display: inline-flex;
    margin-left: spacing(-2);
    margin-right: spacing(-2);

    @mixin breakpoint $breakpointScrollHeader {
      margin-left: spacing(-1.25);
      margin-right: spacing(-1.25);
    }
  }

  &__left__item {
    display: inline-block;
    vertical-align: middle;
    padding-left: spacing(1.25);
    padding-right: spacing(1.25);

    &--burger {
      display: inline-block;
      @mixin breakpoint $breakpointScrollHeader {
        display: none;
      }
    }

    &--primary-cta {
      display: none;
      @mixin breakpoint $breakpointTablet {
        display: inline-block;
      }
    }
  }

  &__center {
    flex-grow: 1;
  }

  &__center__items {
    display: block;
    margin: 0 auto;
    @mixin breakpoint $breakpointScrollHeader {
      display: flex;
      align-items: center;
    }
  }

  &__center__item {
    display: block;

    &--logo {
      @mixin breakpoint $breakpointScrollHeader {
        width: spacing(32);
      }
    }

    &--navigation {
      display: none;
      @mixin breakpoint $breakpointScrollHeader {
        text-align: center;
        display: block;
        flex-grow: 1;
      }
    }

    &--actions {
      display: none;
      @mixin breakpoint $breakpointScrollHeader {
        display: block;
        width: spacing(32);
      }
    }
  }

  &__right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    @mixin breakpoint $breakpointScrollHeader {
      display: none;
    }
  }

  &__right__items {
    display: inline-flex;
    margin-left: spacing(-2);
    margin-right: spacing(-2);

    @mixin breakpoint $breakpointScrollHeader {
      margin-left: spacing(-1.25);
      margin-right: spacing(-1.25);
    }
  }

  &__right__item {
    display: inline-block;
    vertical-align: middle;
    padding-left: spacing(1.25);
    padding-right: spacing(1.25);

    &--secondary-cta {
      display: none;
      @mixin breakpoint $breakpointTablet {
        display: inline-block;
      }
    }

    &--language-switcher {
      display: none;
      @mixin breakpoint $breakpointTablet {
        display: inline-block;
      }
      @mixin breakpoint $breakpointScrollHeader {
        display: none;
      }
    }

    &--phone-number {
      display: inline-block;
      @mixin breakpoint $breakpointTablet {
        display: none;
      }
    }
  }

  &__phone-number {
    width: spacing(4);
    height: spacing(4);
    color: $colorWhite;

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }

    &__link {
      display: flex;
      line-height: 0;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__icon {
      display: block;
      width: spacing(2.5);
    }
  }

  &__brand {
    width: 100%;

    [data-theme="waldhaus"] & {
      color: $waldhausBarley;
    }
  }

  &__logo {
    line-height: 1;
    display: block;
    width: spacing(16);
    color: currentColor;
    margin: 0 auto;
    @mixin breakpoint $breakpointTabletLandscape {
      width: spacing(20);
    }
    @mixin breakpoint $breakpointScrollHeader {
      width: spacing(20);
      margin-left: 0;
    }

    &--waldhaus,
    &--chalet-belmont {
      width: spacing(10);

      @mixin breakpoint $breakpointTablet {
        width: spacing(12);
        opacity: 1;
      }

      @mixin breakpoint $breakpointTabletLandscape {
        width: spacing(14);
        opacity: 1;
      }
      @mixin breakpoint $breakpointScrollHeader {
        width: spacing(16);
      }
    }
  }

  &__actions {
    display: none;
    @mixin breakpoint $breakpointScrollHeader {
      display: flex;
      width: resolve(spacing(15) + spacing(2) + spacing(15));
    }
  }

  &__action {
    width: spacing(15);

    &:not(:last-child) {
      margin-right: spacing(2);
    }
  }

  &__primary-cta,
  &__secondary-cta {
    display: none;
    @mixin breakpoint $breakpointTabletLandscape {
      display: block;
      width: spacing(15);
    }
    @mixin breakpoint $breakpointScrollHeader {
      width: spacing(20);
    }
  }

  &__navigation {
    display: inline-block;
  }

  &__navigation__list {
    list-style-type: none;
  }

  &__navigation__item {
    display: inline-block;
  }

  &__navigation__action {
    display: block;
    padding: spacing(0.8);
    color: $colorWhite;
    line-height: 1.75;

    &.is-active {
      opacity: 1;

      [data-theme="carillon"] & {
        color: $carillonRiverBed;
      }

      [data-theme="waldhaus"] & {
        color: $waldhausYellowMetal;
      }
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__sub-navigation {
    background-color: $colorAlabaster;
    position: relative;
    text-align: center;
    z-index: 1;
    overflow: auto;
    height: spacing(4);

    @mixin breakpoint $breakpointTablet {
      height: spacing(5);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      height: spacing(6);
    }
  }

  &__sub-navigation__container {
    text-align: center;
    padding: 0 spacing(1.5);
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;

    @mixin breakpoint $breakpointTablet {
      padding: 0 spacing(2.5);
    }
  }

  &__sub-navigation__list {
    list-style-type: none;
    display: inline-flex;
    padding: 0;
    margin-bottom: spacing(4) !important;
    align-items: center;
    height: spacing(4);

    @mixin breakpoint $breakpointTablet {
      height: spacing(5);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      height: spacing(6);
    }
  }

  &__sub-navigation__item {
    display: inline-block;
  }

  &__sub-navigation__action {
    display: block;
    padding: spacing(1.7) spacing(1);
    color: $colorWhite;
    line-height: 2;

    &.is-active {
      opacity: 1;

      [data-theme="carillon"] & {
        color: $carillonRiverBed;
      }

      [data-theme="waldhaus"] & {
        color: $waldhausYellowMetal;
      }
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__sub-navigation__title {}

}
