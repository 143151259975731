$component: .contact-bar;
$component  {
  display: none;
  @mixin breakpoint $breakpointTabletLandscape {
    position: fixed;
    bottom: 0;
    left: 0;
    height: $offsetContactBar;
    display: block;
    width: 100%;
    box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);
    background-color: $colorWhite;
    z-index: $zIndexGlobalContactBar;
  }

  &__container {
    padding-left: spacing(4);
    padding-right: spacing(4);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__left{
    width: 100%;
  }

  &__left {
    text-align: left;

    &__items {
      display: flex;
      vertical-align: middle;
      margin-left: spacing(-1.25);
      margin-right: spacing(-1.25);
      align-items: center;
    }

    &__item {
      display: inline-block;
      padding-left: spacing(1.25);
      padding-right: spacing(1.25);
    }
  }

  &__address,
  &__phone-number {
    line-height: spacing(4);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__address__link,
  &__phone-number__link {
    display:block;
  }

  &__phone-number__icon {
    width: spacing(2);
    display: inline-block;
    line-height: 0;
    vertical-align: middle;

    [data-theme="carillon"] & {
      color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      color: $colorBarleyCorn;
    }
  }

  &__language-switcher {
    line-height: spacing(4);
  }
}
