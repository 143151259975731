$component: .utility-grid;
$component  {
  background-color: $colorAlabaster;
  min-height: 100vh;

  &__frame {
    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    text-align: center;
    @mixin breakpoint $breakpointDesktopXLarge {
      max-width: $containerXxlarge;
    }
  }

  &__headline {
    max-width: $containerHeadline;
    text-align: center;
    margin: 0 auto;
    padding: spacing(4) spacing(2.5);

    @mixin breakpoint $breakpointTablet{
      padding: 0 spacing(4) spacing(4) spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }
  }

  &__title {
    padding-bottom: spacing(2);
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__subtitle {
    padding-bottom: spacing(2);
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__cards {
    text-align: left;
    @mixin breakpoint $breakpointTablet {
      font-size: 0;
      margin-left: spacing(-2);
      margin-right: spacing(-2);
    }

    @mixin breakpoint $breakpointDesktop {
      margin-left: spacing(-4);
      margin-right: spacing(-4);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      margin-left: spacing(-5);
      margin-right: spacing(-5);
    }
  }

  &__card {
    @mixin breakpoint $breakpointTablet {
      padding-left: spacing(2);
      padding-right: spacing(2);
      padding-bottom: spacing(4);
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(4);
      padding-right: spacing(4);
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-left: spacing(5);
      padding-right: spacing(5);
      padding-bottom: spacing(5);
    }
  }
}
