$parent: .header;
$component: $(parent)-spacer;
$component  {
  display: none;
  pointer-events: none;

  $(parent)--has-sub-navigation & {
    padding-bottom: spacing(10);
    display: block;

    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(13);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(16);
    }
  }

  $(parent)--has-sub-navigation.header--has-promo-bar & {
    padding-bottom: spacing(14);
    display: block;

    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(17);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(20);
    }
  }

  $(parent)--has-scroll-navigation & {
    padding-bottom: spacing(6);
    display: block;

    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(10);
    }
  }

  $(parent)--has-scroll-navigation.header--has-promo-bar & {
    padding-bottom: spacing(10);
    display: block;

    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(12);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(14);
    }
  }
}
