$component: .utility-card;
$component  {
  position: relative;

  &__frame {}

  &__container {}

  &__media {
    position: relative;
    padding-bottom: aspect-ratio(2,3);
    overflow: hidden;
    height: 0;

    @mixin breakpoint $breakpointTablet {
      margin-bottom: spacing(3);
    }
  }

  &__link {
    display: block;
  }

  &__content {
    padding: spacing(3) spacing(2.5);
    @mixin breakpoint $breakpointTablet {
      padding: 0;
    }
  }

  &__title {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__body {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__cta {

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }
}
