$component: .callout-popup;
$component  {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: $zIndexGlobalPopup;
  display: none;
  left: 0;
  top: 0;
  background-color: rgba($colorBlack, 0.4);

  &.is-active {
    display: block;
  }

  [data-theme="carillon"] & {
    color: $carillonDoveGrey;
  }

  [data-theme="waldhaus"] & {
    color: $waldhausDoveGrey;
  }

  &__frame {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    height: auto;
    padding: spacing(5) 0;
    flex-direction: column;

    @mixin breakpoint $breakpointMobile {
      padding: spacing(5) spacing(2.5);
    }

    @mixin breakpoint $breakpointTablet {
      background-color: transparent;
      padding: spacing(4);
    }
  }

  &__container {
    width: 100%;
    border-radius: spacing(1);
    pointer-events: all;
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0 0 spacing(1) 0 rgba($colorOffBlack, 0.1);
  }

  &__card {
    @mixin breakpoint $breakpointTabletLandscape {
      .callout-card__content-frame {
        max-height: 500px;
        overflow: hidden;
        overflow-y: scroll;
      }
    }
    @mixin uptoBreakpoint $breakpointTabletLandscape {
      .callout-card__content {
        min-height: 50vh;
        display: flex;
        align-items: center;
      }
      .callout-card__media {
        display: none;
      }
    }
  }
}
