$component: .wysiwyg;
$component  {

  &__body {

    a {
      text-decoration: underline;
    }

    ul {
      padding-left: 1.5em;
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }
}
